import {keepPreviousData, useMutation, useQuery, useQueryClient} from "@tanstack/vue-query";
import {
    AccountApi,
    type AccountBankIdModel,
    type AccountModel,
    type PermissionModel
} from "~/gen/openapi/portalService";
import {CUSTOMER_ROLES, CUSTOMER_TYPES, ROLE_FOREST_OWNER} from "~/store/constants/customer";
import {useAccountStore} from "~/stores/account";
import {useUserStore} from "~/stores/user";

export const useAccountService = () => {
    const { $axios, $config } = useNuxtApp()
    const accountApi = new AccountApi(undefined, $config.public.apiBaseHost, $axios)
    const userStore = useUserStore()
    const queryClient = useQueryClient()

    const accountStore = useAccountStore()
    const activeAccount = computed(() => accountStore.activeAccount)
    const activeAccountParent = computed(() => accountStore.activeAccountParent)

    const accountsQuery = () => useQuery({
        queryKey: ['accountGetAccountsByCurrentUser'],
        queryFn: async () => {
            return (await accountApi.accountGetAccountsByCurrentUser()).data;
        },
        staleTime: 60 * 60 * 1000,
        enabled: computed(() => userStore.isLoggedIn),
        refetchOnWindowFocus: false
    })


    const { data: activeAccountData, isFetching: isFetchingActiveAccount, error: errorFetchingActiveAccount } = useQuery({
        queryKey: ['accountGetAccount', activeAccountParent.value || activeAccount.value],
        queryFn: async () => {
            if (accountStore.activeAccountParent || accountStore.activeAccount) {
                return (await accountApi.accountGetAccount(accountStore.activeAccountParent || accountStore.activeAccount!)).data;
            }
        },
        staleTime: 60 * 60 * 1000,
        enabled: computed(()=> !!(accountStore.activeAccountParent || accountStore.activeAccount)),
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData
    })

    const getAccountName = (accounts: Ref<Array<PermissionModel>>) => {
        const account = accounts.value?.find((account) => account.AccountId === accountStore.activeAccount)
        return account?.EntrepreneurName || account?.AccountName || ''
    }

    const primaryForestOwnerAccountPermissionModel = (accounts: Ref<Array<PermissionModel> | undefined>) => {
        return accounts.value?.find((account) => account.Role === ROLE_FOREST_OWNER && account.IsPrimaryAccount)
    }

    const primaryForestOwnerAccountQuery = (primaryForestOwnerAccount?: PermissionModel) => useQuery({
        queryKey: ['accountGetAccount', computed(() => primaryForestOwnerAccount?.AccountId)],
        queryFn: async () => {
            return (await accountApi.accountGetAccount(primaryForestOwnerAccount?.AccountId!)).data;
        },
        enabled: computed(() => !!primaryForestOwnerAccount?.AccountId),
        staleTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false
    })

    const { data: contacts, isFetching: isFetchingContacts, error: errorFetchingContacts } = useQuery({
        queryKey: ['accountGetAccountPropertiesContacts', computed(() => activeAccountData.value?.Id)],
        queryFn: async () => {
            return (await accountApi.accountGetAccountPropertiesContacts(activeAccountData.value?.Id!)).data;
        },
        enabled: computed(() => !!activeAccountData.value),
        staleTime: 60 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    const { isPending: isPendingSaveAccount, error: saveAccountError, isSuccess: saveAccountSuccess, mutate: saveAccount } = useMutation({
        mutationFn: (data: AccountBankIdModel) => {
            if (!data.Account?.Id) {
                throw new Error('Missing account id')
            }
            return accountApi.accountUpdateAccountBankId(data.Account.Id, data)
        },
        onSuccess(response) {
            queryClient.setQueryData(['accountGetAccount', activeAccount],
                (oldData: AccountModel) => {
                    const responseData = response.data as unknown as AccountModel
                    if (responseData.Id) {
                        return responseData
                    }

                    return oldData
                })
        },
        onError(error, _variables) {
            console.error(error)
        },
    })

    const getRoles = (accounts: Ref<Array<PermissionModel>>) => {
        const a: any[] = []
        // Role 1: Skogeier, Role 2: Entreprenør. Role 4: Accountant,
        // AccessLevel 1: Full (admin), AccessLevel 2: Limited.
        CUSTOMER_ROLES.forEach((role) => {
            const roleAccounts = accounts.value?.filter(
                (account) => account.Role === role.id
            )

            if (roleAccounts?.length) {
                a.push({
                    accounts: roleAccounts,
                    name: role.name,
                    path: role.path,
                    id: role.id,
                })
            }
        })

        return a
    }

    const accountRole = (accountId: string, accounts: Ref<Array<PermissionModel>>) => {
        const account = accounts.value?.find((account) => account.AccountId === accountId)
        return account ? account.Role : null
    }

    // https://dev.azure.com/GlommenMjosen/Portaler%20og%20SBL-app/_workitems/edit/1538
    // Properties (and by extension - accounts) can be marked as "excluded" from the normal verification flow.
    const getUserShouldVerify = (primaryForestOwnerAccount: Ref<AccountModel>) => {
        const {$config} = useNuxtApp()
        if ($config.public.features.excludeAllFromVerification) {
            return false
        }
        return primaryForestOwnerAccount.value?.IsExcludedFromVerification === false
    }

    /**
     * Handles both data fetching and state management
     * @param accountId
     * @param accounts
     */
    const setActiveAccount = (accountId: string, accounts: Ref<Array<PermissionModel>>) => {
        const account = accounts.value?.find((account) => account.AccountId === accountId);

        if (account) {
            return accountStore.setActive(accountId, account);
        }

        // If account not found, watch for when accounts data is available
        const accountsWatcher = watch(accounts, (newAccounts) => {
            if (newAccounts?.length) {
                const account = newAccounts.find((account) => account.AccountId === accountId);
                if (account) {
                    accountStore.setActive(accountId, account);
                    accountsWatcher(); // Stop watching once the account is set
                }
            }
        });
    };

    /**
     * Uses active account if no accountId is provided
     */
    const isSilvicultureAccount = (accounts: Ref<Array<PermissionModel>>, accountId?: string) => {
        const id = accountId || accountStore.activeAccount
        if (!id) {
            throw new Error('Failed to check silviculture account: missing account id')
        }

        const account = accounts.value?.find((account) => account.AccountId === accountId)
        return account && account.Role === 2
    }

    const getBasePath = (accounts: Ref<Array<PermissionModel>>) => {
        const accountId = accountStore.activeAccount
        if (!accountId) {
            return '/'
        }

        const account = accounts.value?.find((item) => item.AccountId === accountId)
        if (account && account.Role) {
            const role = CUSTOMER_ROLES.find((role) => role.id === account.Role)
            return role ? `/${role.path}/${accountId}` : '/'
        }
        return '/'
    }

    const accountantId = computed(() => {
        return activeAccountData.value?.AccountantId
    })

    const accountantName = computed(() => {
        return activeAccountData.value?.AccountantName
    })

    const hasRole = (roleType: keyof typeof CUSTOMER_TYPES) => {
        return (
            roleType in CUSTOMER_TYPES &&
            activeAccountData.value &&
            'CustomerType' in activeAccountData.value &&
            CUSTOMER_TYPES[roleType].includes(activeAccountData.value.CustomerType!)
        )
    }

    const accountTeamName = computed(() => {
        const accountData = activeAccountData.value
        return accountData
            // @ts-ignore
            ? accountData.EntrepreneurName &&   // @ts-ignore
            accountData.AccountName &&  // @ts-ignore
            accountData.AccountName.indexOf(accountData.EntrepreneurName) === 0	// @ts-ignore
                ? accountData.AccountName.substring(    // @ts-ignore
                    accountData.EntrepreneurName.length + 1
                )   // @ts-ignore
                : accountData.AccountName
            : ''
    })

    /**
     * If access level is 1, or if user has another account with the same contractor ID and access level 1
     * @param activeAccountId
     * @param accounts
     */
    const getHasAdminAccess = (activeAccountId: string, accounts: Ref<PermissionModel[]>) => {
        const accountData = accounts.value?.find((account) => account.AccountId === activeAccountId)
        return accountData && accountData.AccessLevel === 1
    }

    return {
        accountsQuery,
        hasRole,
        primaryForestOwnerAccountQuery,
        primaryForestOwnerAccountPermissionModel,
        getRoles,
        accountRole,
        getUserShouldVerify,
        setActiveAccount,
        isSilvicultureAccount,
        getBasePath,
        activeAccountData,
        isFetchingActiveAccount,
        errorFetchingActiveAccount,
        getAccountName,
        accountantId,
        accountantName,
        accountTeamName,
        contacts,
        isFetchingContacts,
        errorFetchingContacts,
        isPendingSaveAccount,
        saveAccountError,
        saveAccountSuccess,
        saveAccount,
        getHasAdminAccess,
    }
}