/* tslint:disable */
/* eslint-disable */
/**
 * Portal API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AccountBankIdModel } from '../model';
// @ts-ignore
import { AccountContactModel } from '../model';
// @ts-ignore
import { AccountModel } from '../model';
// @ts-ignore
import { MessageModel } from '../model';
// @ts-ignore
import { OptionModel } from '../model';
// @ts-ignore
import { PermissionModel } from '../model';
// @ts-ignore
import { PropertiesContactModel } from '../model';
// @ts-ignore
import { PropertyForestFundModel } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccount: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountGetAccount', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contact persons based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountContacts: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountGetAccountContacts', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/account-contacts`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account documents based on account ID
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountDocuments: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountGetAccountDocuments', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get GM contact persons on properties based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountPropertiesContacts: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountGetAccountPropertiesContacts', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/contacts`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get accounts by currentUser.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountsByCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get accounts based on accounttype.
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountsByType: async (type: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('accountGetAccountsByType', 'type', type)
            const localVarPath = `/api/v1/accounts/type/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forest funds based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetForestFunds: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountGetForestFunds', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/forestfund`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get role types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request timber pricelist
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestTimberPriceList: async (accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountRequestTimberPriceList', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/timberPriceList`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a message by e-mail.
         * @param {string} accountId 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendMessage: async (accountId: string, messageModel?: MessageModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountSendMessage', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}/message`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account.
         * @param {string} accountId 
         * @param {AccountBankIdModel} [accountBankIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateAccountBankId: async (accountId: string, accountBankIdModel?: AccountBankIdModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountUpdateAccountBankId', 'accountId', accountId)
            const localVarPath = `/api/v1/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountBankIdModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccount(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccount(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contact persons based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccountContacts(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountContactModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccountContacts(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccountContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get account documents based on account ID
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccountDocuments(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccountDocuments(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccountDocuments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get GM contact persons on properties based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccountPropertiesContacts(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertiesContactModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccountPropertiesContacts(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccountPropertiesContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get accounts by currentUser.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccountsByCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccountsByCurrentUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccountsByCurrentUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get accounts based on accounttype.
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetAccountsByType(type: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetAccountsByType(type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetAccountsByType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get forest funds based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetForestFunds(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyForestFundModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetForestFunds(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetForestFunds']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get role types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetRoles(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get account types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountGetTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Request timber pricelist
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountRequestTimberPriceList(accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountRequestTimberPriceList(accountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountRequestTimberPriceList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a message by e-mail.
         * @param {string} accountId 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountSendMessage(accountId: string, messageModel?: MessageModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountSendMessage(accountId, messageModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountSendMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update account.
         * @param {string} accountId 
         * @param {AccountBankIdModel} [accountBankIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateAccountBankId(accountId: string, accountBankIdModel?: AccountBankIdModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateAccountBankId(accountId, accountBankIdModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.accountUpdateAccountBankId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get account based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccount(accountId: string, options?: any): AxiosPromise<AccountModel> {
            return localVarFp.accountGetAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contact persons based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountContacts(accountId: string, options?: any): AxiosPromise<Array<AccountContactModel>> {
            return localVarFp.accountGetAccountContacts(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account documents based on account ID
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountDocuments(accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountGetAccountDocuments(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get GM contact persons on properties based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountPropertiesContacts(accountId: string, options?: any): AxiosPromise<Array<PropertiesContactModel>> {
            return localVarFp.accountGetAccountPropertiesContacts(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get accounts by currentUser.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountsByCurrentUser(options?: any): AxiosPromise<Array<PermissionModel>> {
            return localVarFp.accountGetAccountsByCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get accounts based on accounttype.
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAccountsByType(type: number, options?: any): AxiosPromise<Array<AccountModel>> {
            return localVarFp.accountGetAccountsByType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forest funds based on account ID.
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetForestFunds(accountId: string, options?: any): AxiosPromise<Array<PropertyForestFundModel>> {
            return localVarFp.accountGetForestFunds(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get role types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetRoles(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.accountGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get account types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTypes(options?: any): AxiosPromise<Array<OptionModel>> {
            return localVarFp.accountGetTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request timber pricelist
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestTimberPriceList(accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountRequestTimberPriceList(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a message by e-mail.
         * @param {string} accountId 
         * @param {MessageModel} [messageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountSendMessage(accountId: string, messageModel?: MessageModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountSendMessage(accountId, messageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update account.
         * @param {string} accountId 
         * @param {AccountBankIdModel} [accountBankIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateAccountBankId(accountId: string, accountBankIdModel?: AccountBankIdModel, options?: any): AxiosPromise<void> {
            return localVarFp.accountUpdateAccountBankId(accountId, accountBankIdModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get account based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccount(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contact persons based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccountContacts(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccountContacts(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account documents based on account ID
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccountDocuments(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccountDocuments(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get GM contact persons on properties based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccountPropertiesContacts(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccountPropertiesContacts(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get accounts by currentUser.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccountsByCurrentUser(options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccountsByCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get accounts based on accounttype.
     * @param {number} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAccountsByType(type: number, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetAccountsByType(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forest funds based on account ID.
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetForestFunds(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetForestFunds(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get role types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetRoles(options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get account types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetTypes(options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountGetTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request timber pricelist
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRequestTimberPriceList(accountId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountRequestTimberPriceList(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a message by e-mail.
     * @param {string} accountId 
     * @param {MessageModel} [messageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountSendMessage(accountId: string, messageModel?: MessageModel, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountSendMessage(accountId, messageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update account.
     * @param {string} accountId 
     * @param {AccountBankIdModel} [accountBankIdModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateAccountBankId(accountId: string, accountBankIdModel?: AccountBankIdModel, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdateAccountBankId(accountId, accountBankIdModel, options).then((request) => request(this.axios, this.basePath));
    }
}

